body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.header-logo {
  max-width: 50px; 
  max-height: 30px; 
  margin: 0 auto; 
  display: block; 
}

.sportsbook-logo {
  max-width: 60%; 
  max-height: 60%; 
  object-fit: contain; 
  margin: 0; 
  display: block;
}

.sportsbook-bar {
  display: flex;
  justify-content: center; 
  align-items: center; 
  background-color: #004aad; 
  padding: 10px;
  border-radius: 8px; 
  margin-bottom: 20px; 
}

.sportsbook-bar-logo {
  height: 40px; 
  margin: 0 10px; 
  object-fit: contain; 
  filter: brightness(0) invert(1); 
}

.game-logos {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.team-logo {
  width: 100px; 
  height: auto;
}

.vs-info {
  font-size: 16px;
  font-weight: bold;
  color: #004aad; 
  text-align: center;
}

.header {
  background-color: #000; 
  padding: 15px; 
  text-align: center;
  width: 100vw; 
  margin: 0; 
  box-sizing: border-box;
}

.title {
  font-size: 40px; 
  font-weight: bold; 
  text-align: center; 
  font-family: Arial, Helvetica, sans-serif; 
  color: transparent; 
  -webkit-text-stroke: 2px #034ab0; 
  letter-spacing: 2px; 
  display: inline-block;
}

.title:hover span {
  color: #034ab0; 
}

.title span {
  opacity: 0; 
  display: inline-block;
  transform: scale(0.8); 
  animation: popIn 1s ease forwards;
  transition: ease-in;
}

.title span:hover {
  color: #034ab0; 
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.content {
  background-color: #fff; 
  padding: 20px; 
} 

.odds-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.odds-table th {
  background-color: #fff;
  padding: 10px; 
  text-align: center;
  border: none;
  position: sticky;
  top: 0;
  z-index: 2;
}

.odds-table td, .odds-table th {
  text-align: center; 
  vertical-align: middle; 
}

.odds-table td {
  background-color: #fff;
  text-align: center;
  padding: 15px;
  border: none;
}

.game-cell {
  font-weight: bold;
  text-align: left;
  padding-left: 20px;
  color: #004aad; 
}

.sportsbook-cell .odds-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.odds-value {
  font-weight: bold;
  font-size: 16px;
  color: #333; 
}

.odds-grey {
  font-size: 14px;
  color: #999; 
}

.animated-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 10; 
  animation: slideUp 1s ease-in forwards;
}

.static-header {
  position: sticky;
  top: 0;
  background-color: black;
  width: 100%;
  z-index: 5;
}

@keyframes slideUp {
  0% {
    transform: translateY(0); 
  }
  100% {
    transform: translateY(-100%); 
  }
}
